import React from "react";
import logo from './assets/images/fried-pickle-labs.svg';
import picklesBG from './assets/images/pickles_bg.png';
import picklesTop from './assets/images/pickles_top.png';
import './App.scss';

function App() {
    return (
        <div className="App">
            <div className="container">
                <div className="header">
                    <img src={logo} />
                </div>
                <div className="content">
                    <div className="leftPane">
                        <div className="title">
                            This page is coming soon…
                        </div>
                        <div className="text">
                            For questions or inquiries please contact us at <a href="mailto:support@friedpicklelabs.com" className="mail">support@friedpicklelabs.com</a>
                        </div>
                        <div className="text">
                            972-74-7990001
                        </div>
                        <div className="button googlePlay" onClick={() => window.location.href="https://play.google.com/store/apps/dev?id=4915022087914306218"}>
                            Google Play
                        </div>
                    </div>
                    <div className="pickleLogo">
                        <img src={picklesBG} className="picklesBG rotate" />
                        <img src={picklesTop} className="picklesTop" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
